import React, { useState, useRef } from "react"
import { Link } from "gatsby"

import { useForm } from "react-hook-form";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faCheck, faTimes, faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => {
  const [isSending, setIsSending] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailure, setIsFailure] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const token = useRef(null);

  const { handleSubmit, register, errors, formState } = useForm({ mode: "onChange" });

  const spinner = <span>Sending... <FontAwesomeIcon className="ml-2" icon={faSpinner} pulse /></span>;

  const sendMail = async (values, e) => {
    setIsSending(true);
    setIsSuccess(false);
    setIsFailure(false);
    setErrorMessage("");

    try {
      await axios.post(`/api/sendMail.php`, values);
      setIsSuccess(true);
      e.target.reset();
    } catch (err) {
      console.error(err);
      setIsFailure(true);
      setErrorMessage("Message was not sent.");
    } finally {
      setIsSending(false);
    }

  };

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LebkMkUAAAAAEkv8NT29-gmspN7e-MR4GQ9YZau">
      <Layout>
        <SEO title="Page two" />
        <h1>Hi from the second page</h1>
        <p>Welcome to page 2</p>
        <Link to="/">Go back to the homepage</Link>
        <div className="row">
          <div className="col-xs-12 col-sm-8">
            <form onSubmit={handleSubmit(sendMail)}>
              <div className="form-group">
                <label htmlFor="name" className={`form-text${errors.name ? " text-danger" : ""}`}>Name (required)</label>
                <input
                  type="text"
                  className={`form-control${errors.name ? " is-invalid" : ""}`}
                  id="name"
                  name="name"
                  ref={register({ required: true })}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email" className={`form-text${errors.email ? " text-danger" : ""}`}>Email (required)</label>
                <input
                  type="email"
                  className={`form-control${errors.email ? " is-invalid" : ""}`}
                  id="email"
                  name="email"
                  ref={register({ required: true })}
                />
              </div>
              <div className="form-group">
                <label htmlFor="subject" className={`form-text${errors.subject ? " text-danger" : ""}`}>Subject</label>
                <input
                  type="text"
                  className={`form-control${errors.subject ? " is-invalid" : ""}`}
                  id="subject"
                  name="subject"
                  ref={register}
                />
              </div>
              <div className="form-group">
                <label htmlFor="message" className={`form-text${errors.message ? " text-danger" : ""}`}>Message (required)</label>
                <textarea
                  className={`form-control${errors.message ? " is-invalid" : ""}`}
                  rows={6}
                  style={{ resize: "vertical" }}
                  id="message"
                  name="message"
                  ref={register({ required: true })}
                />
              </div>
              <GoogleReCaptcha onVerify={response => token.current = response} />
              <button type="submit" className="btn btn-primary" disabled={!token.current || !formState.isValid || isSending}>{isSending ? spinner : "Send Message"}</button>
              {isSuccess ? <span className="text-success ml-3"><FontAwesomeIcon icon={faCheck} /> Message sent successfully</span> : null}
              {isFailure ? <span className="text-danger ml-3"><FontAwesomeIcon icon={faTimes} /> {errorMessage}</span> : null}
            </form>

          </div>

          <div className="col-xs-12 col-sm-4">
            <h4>Contact Information:</h4>
            <p>D-Function</p>
            <p>Ms. Artemis Moustaka</p>
            <p>36 Elenis Zografou St.</p>
            <p>Ioannina</p>
            <p>45332 GREECE</p>
            <br />
            <p><FontAwesomeIcon icon={faPhone} />&nbsp;+30 2651 067600</p>
            <p><FontAwesomeIcon icon={faEnvelope} />&nbsp;<a href="mailto:akm@delta-function.com">akm@delta-function.com</a></p>
          </div>
        </div>
      </Layout>
    </GoogleReCaptchaProvider>
  );
};

export default SecondPage
